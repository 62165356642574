import platform from 'platform'

export default function () {
  if (platform.name === 'Safari') {
    addPlatformStyling(SAFARI_STYLES)
  }
  if (platform.os.family.includes('Windows')) {
    addPlatformStyling(WINDOWS_STYLES)
  }
}

const SAFARI_STYLES = `
  .page-content {
    height: 9.5in !important;
  }
  .page {
    height: 10.3in !important;
  }
`

const WINDOWS_STYLES = `
  #outline-sortable {
    max-height: 64vh;
  }
`

function addPlatformStyling(content) {
  const style = document.createElement('style')
  style.textContent = content
  document.head.appendChild(style)
}
