import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(utc)

import { rerenderMathJax } from 'clients/mathjax'
import PortalVue from 'portal-vue'
import VueObserveVisibility from 'vue3-observe-visibility'

export default function applyPlugins(vueApp) {
  vueApp.directive('mathjax', rerenderMathJax)
  vueApp.use(PortalVue)
  vueApp.use(VueObserveVisibility)
  return vueApp
}
