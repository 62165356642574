import { createPage, pageHeight, renderParentChain, moveBreakAfterAvoids, isOverflowing } from './page'
import {
  getBreakStyle,
  lowestChildNode,
  nodeMargin,
  parentBottomSpacing,
  removeBrokenElement,
  loadImages,
  hasContent,
  removeEmptyParents,
  selectByDataRef,
  deepClone,
  containsPrintedTag,
  hasTextInTree,
  hasText,
} from './dom'
import { addMovedTextToParent, removeUnusedNodes, reversedWords, splitOnWord } from './text_splitter'

export default async function renderFlexingItem(flexElement, parentChain, pages) {
  let page = pages[pages.length - 1]
  const renderedParent = renderParentChain(parentChain, page)
  const newFlexItem = renderedParent.appendChild(deepClone(flexElement))
  await loadImages(newFlexItem)

  let movedAlready = false
  if (isTooLowOnPage(newFlexItem, page)) {
    const newPage = createPage()
    pages.push(newPage)
    moveFullItem(newFlexItem, newPage, page, parentChain)
    page = newPage
    movedAlready = true
  }

  const overflowingNodes = overflowingNodesFromPage(newFlexItem, page)

  if (overflowingNodes.length > 0) {
    breakFlexItem(newFlexItem, parentChain, overflowingNodes, pages)
  } else if (isOverflowing(page) && !movedAlready) {
    const newPage = createPage()
    pages.push(newPage)
    moveFullItem(newFlexItem, newPage, page, parentChain)
  }
}

function isTooLowOnPage(flexElement, page) {
  const styles = window.getComputedStyle(flexElement)
  const minHeight = Number.parseInt(styles.getPropertyValue('min-height'))
  return (
    offsetTopToPage(flexElement, page) + minHeight + nodeMargin(flexElement) + parentBottomSpacing(flexElement) >
    pageHeight(page)
  )
}

function breakFlexItem(newFlexItem, parentChain, overflowingNodes, pages) {
  const page = pages[pages.length - 1]
  const newPage = createPage()
  pages.push(newPage)

  if (getBreakStyle(newFlexItem)['inside'] === 'avoid') {
    moveFullItem(newFlexItem, newPage, page, parentChain)
  } else {
    breakUpItemInChunks(page, newPage, overflowingNodes, parentChain, newFlexItem, pages)
  }
}

function moveFullItem(newFlexItem, newPage, originalPage, parentChain) {
  removeBrokenElement(newFlexItem)
  const renderedParent = renderParentChain(parentChain, newPage)
  renderedParent.appendChild(newFlexItem)
  moveBreakAfterAvoids(originalPage, newPage)
}

function breakUpItemInChunks(lastPage, currentPage, overflowingNodes, parentChain, newFlexItem, pages) {
  let tries = 0
  let lastFlexItem = newFlexItem
  while (overflowingNodes.length > 0 && tries < 10) {
    const renderedParent = renderParentChain(parentChain, currentPage)
    const nextPageFlex = renderedParent.appendChild(cloneFlexItem(newFlexItem))
    moveOverflow(nextPageFlex, newFlexItem, lastPage, overflowingNodes)
    overflowingNodes = overflowingNodesFromPage(nextPageFlex, currentPage)
    if (overflowingNodes.length > 0) {
      lastPage = currentPage
      currentPage = createPage()
      pages.push(currentPage)
    }
    adjustBorders(lastFlexItem, nextPageFlex)
    lastFlexItem = nextPageFlex
    tries++
  }
  if (newFlexItem.childNodes.length === 0) {
    removeBrokenElement(newFlexItem)
  }
}

function adjustBorders(lastPageFlex, newPageFlex) {
  const emptyRefs = []
  newPageFlex.querySelectorAll(':scope > .border-solid').forEach((child) => {
    if (Array.from(child.childNodes).length === 0) {
      child.classList.add('border-0')
      emptyRefs.push(child.getAttribute('data-ref'))
    } else {
      child.classList.remove('border-b-0')
      child.style.removeProperty('border-bottom-right-radius')
      child.style.removeProperty('border-bottom-left-radius')

      child.classList.add('border-t-0')
      child.style.setProperty('border-top-right-radius', '0px', 'important')
      child.style.setProperty('border-top-left-radius', '0px', 'important')
    }
  })
  lastPageFlex.querySelectorAll(':scope > .border-solid').forEach((child) => {
    if (Array.from(child.childNodes).length === 0) {
      child.classList.add('border-0')
    } else if (!emptyRefs.includes(child.getAttribute('data-ref'))) {
      child.classList.add('border-b-0')
      child.style.setProperty('border-bottom-right-radius', '0px', 'important')
      child.style.setProperty('border-bottom-left-radius', '0px', 'important')
    }
  })
}

function cloneFlexItem(flexItem) {
  const copiedNode = flexItem.cloneNode()
  Array.from(flexItem.children).forEach((child) => {
    copiedNode.appendChild(child.cloneNode())
  })
  return copiedNode
}

function overflowingNodesFromPage(flexItem, page) {
  const overflowingNodes = []
  Array.from(flexItem.childNodes)
    .flatMap((child) => Array.from(child.childNodes))
    .forEach((node) => {
      if (nodeOffPage(node, page)) {
        addOverflowingNode(overflowingNodes, node, page)
      }
    })
  return overflowingNodes
}

function addOverflowingNode(overflowingNodes, node, page) {
  const baseElements = getBaseElements(node)
  if (baseElements.length > 0) {
    Array.from(baseElements).forEach((element) => {
      if (nodeOffPage(element, page)) {
        overflowingNodes.push(element)
      }
    })
  } else {
    overflowingNodes.push(node)
  }
}

function getBaseElements(node) {
  if (hasContent(node) || node.children.length === 0 || getBreakStyle(node).inside === 'avoid') {
    return [node]
  } else {
    return Array.from(node.children)
      .map((node) => getBaseElements(node))
      .flat()
  }
}

function moveOverflow(newFlexParent, flexParent, page, overflowingNodes) {
  let tries = 0
  // break nodes until they fit in the flexParent
  while (overflowingNodes.length > 0 && tries < 200) {
    overflowingNodes.forEach((node) => {
      iterateNodeBreak(node, newFlexParent, flexParent, pageHeight(page))
    })
    overflowingNodes = overflowingNodes.filter((node) => {
      return page.contains(node) && nodeOffPage(node, page)
    })
    tries++
  }
  fixLists(newFlexParent, flexParent)
  return []
}

function fixLists(newFlexParent, oldFlexParent) {
  newFlexParent.querySelectorAll('ol,ul').forEach((list) => {
    list.querySelectorAll('li').forEach((li) => {
      if (!containsPrintedTag(li) && !hasText(li) && !hasTextInTree(li)) {
        li.remove()
      }
    })
    const oldList = selectByDataRef(list, oldFlexParent)
    if (oldList) {
      setNewStart(list, oldList)
      const li = list.querySelector('li')
      if (li) {
        li.style = 'list-style: none;'
      }
    }
  })
}

function setNewStart(list, oldList) {
  const newStart = oldList.querySelectorAll('li').length + parseInt(oldList.getAttribute('start') || 0)
  list.setAttribute('start', newStart)
}

function nodeOffPage(node, page) {
  return (
    offsetTopToPage(node, page) + node.offsetHeight + nodeMargin(node) + parentBottomSpacing(node) > pageHeight(page)
  )
}

function offsetTopToPage(node, page) {
  const pageTop = page.querySelector('.page-content')
  let top = node.offsetTop
  let offsetParent = node.offsetParent
  while (offsetParent && offsetParent !== pageTop) {
    top += offsetParent.offsetTop
    offsetParent = offsetParent.offsetParent
  }
  return top
}

function iterateNodeBreak(node, newFlexParent, flexParent, pageHeight) {
  if (node.nodeName === '#comment') {
    node.remove()
  } else if (node.nodeName === '#text') {
    const { movedText, wordStart } = splitOnWord(node, reversedWords(node)[0])
    addMovedTextToParent(newFlexParent, flexParent, movedText, node)
    removeUnusedNodes(node, wordStart)
  } else if (node.nodeName === 'TABLE') {
    breakTable(node, newFlexParent)
  } else if (cannotBreakNode(node)) {
    const oldParent = node.parentElement
    addOverflowToNewChunk(node, newFlexParent)
    removeEmptyParents(oldParent)
  } else {
    iterateNodeBreak(lowestChildNode(node), newFlexParent, flexParent, pageHeight)
  }
}

function cannotBreakNode(node) {
  return (
    node.querySelector &&
    (node.querySelector('.math-tex') ||
      node.childNodes.length === 0 ||
      (getBreakStyle(node).inside === 'avoid' && node.clientHeight < pageHeight) ||
      hasContent(node))
  )
}

function breakTable(table, newFlexParent) {
  const lastRow = table.querySelector('tr:last-child')

  if (!selectByDataRef(table, newFlexParent)) {
    buildOrSelectNewParent(table, newFlexParent).append(table.cloneNode())
  }

  const newParent = buildOrSelectNewParent(lastRow, newFlexParent)
  newParent.prepend(lastRow)

  if (!table.querySelector('td')) {
    table.remove()
  }
}

function addOverflowToNewChunk(node, newFlexParent) {
  const newParent = buildOrSelectNewParent(node, newFlexParent)

  newParent.append(node)
}

function buildOrSelectNewParent(node, newFlexContainer) {
  const parents = []
  let newParent = node.parentElement
  while (!selectByDataRef(newParent, newFlexContainer.parentElement)) {
    parents.unshift(newParent)
    newParent = newParent.parentElement
  }
  if (parents.length) {
    let lastParent = selectByDataRef(newParent, newFlexContainer.parentElement)
    for (const parent of parents) {
      lastParent = lastParent.appendChild(parent.cloneNode())
    }
    return lastParent
  } else {
    return selectByDataRef(newParent, newFlexContainer.parentElement)
  }
}
