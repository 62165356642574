<template>
  <!-- safe because the SVG content is read from our images directory -->
  <!-- eslint-disable-next-line vue/no-v-html, vue/no-v-text-v-html-on-component -->
  <component :is="tag" :class="['loading', optionsClasses]" v-html="$options.images.loadingSpinner" />
</template>

<script>
/* eslint-disable vue/component-api-style */
import loadingSpinner from 'images/icons/loading-spinner.svg'

export const SIZES = ['', 'small', 'large']

export default {
  name: 'LoadingSpinner',
  images: { loadingSpinner },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
      validator: (value) => SIZES.includes(value),
    },
    monochrome: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    optionsClasses() {
      const classes = []
      if (this.inline) classes.push('loading--inline')
      if (this.size) classes.push(`loading--${this.size}`)
      if (this.monochrome) classes.push('loading--monochrome')
      return classes.join(' ')
    },
  },
}
</script>
