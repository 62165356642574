<template>
  <component
    :is="type"
    v-if="backgroundColorClass"
    :class="['inline-grid place-items-center', backgroundColorClass, backgroundSizeClass]"
    :type="type === 'button' ? 'button' : null"
  >
    <svg
      class="col-span-full row-span-full h-full w-full"
      :class="backgroundColorClass"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="currentColor" />
    </svg>
    <InlineSvg :path="path" :class="[' col-span-full row-span-full h-auto', iconColorClass, iconSizeClass]" />
  </component>
  <component
    :is="type"
    v-else
    class="inline-flex items-center"
    :class="iconSizeClass"
    :type="type === 'button' ? 'button' : null"
  >
    <InlineSvg class="h-full w-full" :path="path" :class="iconColorClass" />
  </component>
</template>

<script>
import { computed } from 'vue'
import InlineSvg from './InlineSvg'
import colors from 'tailwindcss/colors'

delete colors['lightBlue'] // Avoid a deprecation warning

const supportedColorClasses = Object.keys(colors)
  .map((color) => {
    if (typeof colors[color] === 'string') {
      return `text-${color}`
    } else {
      return Object.keys(colors[color]).map((colorValue) => `text-${color}-${colorValue}`)
    }
  })
  .flat()

export const SIZES = ['sm', 'md', 'lg', 'xl', 'custom']
export const TYPES = ['span', 'div', 'a', 'button']
const SIZE_CLASSES = {
  icon: {
    sm: 'w-5 h-5',
    md: 'w-6 h-6',
    lg: 'w-10 h-10',
    xl: 'w-16 h-16',
    custom: '',
  },
  background: {
    sm: 'w-6 h-6',
    md: 'w-8 h-8',
    lg: 'w-12 h-12',
    xl: 'w-20 h-20',
    custom: '',
  },
}

export default {
  name: 'LzIcon',
  components: { InlineSvg },
  props: {
    path: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => SIZES.includes(value),
    },
    backgroundColorClass: {
      type: String,
      default: '',
      validator: (value) => value === '' || supportedColorClasses.includes(value),
    },
    iconColorClass: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'span',
      validator: (value) => TYPES.includes(value),
    },
  },
  setup(props) {
    const iconSizeClass = computed(() => SIZE_CLASSES.icon[props.size])
    const backgroundSizeClass = computed(() => SIZE_CLASSES.background[props.size])

    return { iconSizeClass, backgroundSizeClass }
  },
}
</script>
