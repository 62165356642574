<template>
  <CollapseTransition>
    <div
      v-if="!isDismissed"
      :class="[
        stateInfo.alertBackground,
        stateInfo.textColor,
        'relative z-10 flex items-center justify-center px-10 pb-3 pt-2 print:hidden',
        { 'border-t border-white': hasTopBorder },
      ]"
    >
      <button
        v-if="dismissible"
        data-test="dismiss-banner-button"
        aria-label="dismiss banner"
        class="absolute right-4 h-6 w-6 transform cursor-pointer opacity-80 transition-all duration-300 hover:rotate-90 hover:opacity-100"
        @click="handleDismiss"
      >
        <InlineSvg path="icons/close" :class="['h-6 w-6', stateInfo.textColor]" />
      </button>
      <div class="flex flex-col items-center gap-2 sm:flex-row sm:justify-center">
        <a v-if="link" :href="link" :class="[stateInfo.linkColor, 'inline cursor-pointer']">
          {{ text }}
        </a>
        <p v-else-if="text" :class="[stateInfo.textColor, 'inline']">{{ text }}</p>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="sanitizedContent"></p>
        <slot />
      </div>
    </div>
  </CollapseTransition>
</template>

<script>
import InlineSvg from './InlineSvg'
import { CollapseTransition } from 'vue_features/shared/components/transitions'
import { ref, computed } from 'vue'

export const STATES = {
  default: {
    alertBackground: 'bg-primary-accent',
    textColor: 'text-inverted',
    linkColor: 'text-accent-inverted hover:text-accent-inverted-hover',
  },
  inverted: {
    alertBackground: 'bg-inverted',
    textColor: 'text-inverted',
    linkColor: 'text-accent-inverted hover:text-accent-inverted-hover',
  },
  error: {
    alertBackground: 'bg-red-200',
    textColor: 'text-red-800',
    linkColor: 'text-accent hover:text-accent-hover',
  },
  notice: {
    alertBackground: 'bg-orange-200',
    textColor: 'text-orange-800',
    linkColor: 'text-accent hover:text-accent-hover',
  },
  success: {
    alertBackground: 'bg-green-200',
    textColor: 'text-green-800',
    linkColor: 'text-accent hover:text-accent-hover',
  },
  neutral: {
    alertBackground: 'bg-neutral-800',
    textColor: 'text-white',
    linkColor: 'text-accent hover:text-accent-hover',
  },
}
export default {
  name: 'Banner',
  components: { CollapseTransition, InlineSvg },
  props: {
    type: {
      type: String,
      default: 'default',
      validator: (value) => Object.keys(STATES).includes(value),
    },
    text: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    hasTopBorder: {
      type: Boolean,
      default: true,
    },
    sanitizedContent: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const isDismissed = ref(false)
    const stateInfo = computed(() => STATES[props.type])
    const alertShell = computed(
      () => `${stateInfo.value.alertBackground} ${stateInfo.value.alertBorder} ${stateInfo.value.textColor}`,
    )
    function handleDismiss() {
      isDismissed.value = true
      emit('dismissed')
    }
    return { handleDismiss, isDismissed, stateInfo, alertShell }
  },
}
</script>
