import URI from 'urijs'

const location = {
  assign(url) {
    return window.location.assign(url)
  },

  newTab(url) {
    return window.open(url, '_blank')
  },

  current() {
    return window.location
  },

  domain() {
    return new URI(window.location).domain()
  },

  subdomain() {
    return new URI(window.location).subdomain()
  },

  href() {
    return window.location.href
  },

  pathname() {
    return window.location.pathname
  },

  protocol() {
    return window.location.protocol
  },

  hash() {
    return window.location.hash
  },

  reload() {
    return window.location.reload()
  },

  replace(url) {
    return window.location.replace(url)
  },

  openTab(url) {
    return window.open(url, '_blank')
  },

  search(query) {
    if (query === undefined) return window.location.search
    return (window.location.search = query)
  },

  parseQuery() {
    return URI.parseQuery(window.location.search)
  },

  parseURI() {
    return URI.parse(window.location.href)
  },

  deleteQueryFromURL(key) {
    let uri = new URI(this.parseURI())
    if (uri.hasQuery(key)) {
      uri = uri.removeQuery(key)
      window.history.replaceState({}, document.title, uri.toString())
    }
  },
}

export default location
