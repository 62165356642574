import { ref, isReactive, toRefs, watch, effectScope } from 'vue'
import cloneDeep from 'lodash/cloneDeep'

const definedStores = new Set()
const activeStores = new Map()
const initialStates = new Map()

export const storeTracker = ref([])

export function getStore(storeId) {
  if (process.env.NODE_ENV !== 'development' && !__VUE_PROD_DEVTOOLS__) {
    throw new Error('getStore is for development purposes only')
  }
  return activeStores.get(storeId)
}

function removeStore(storeId) {
  storeTracker.value.splice(storeTracker.value.indexOf(storeId), 1)
  definedStores.delete(storeId)
  activeStores.delete(storeId)
  initialStates.delete(storeId)
}

export function defineStore(storeId, fn) {
  if (definedStores.has(storeId)) {
    throw new Error(`Store already exists with id ${storeId}.`)
  }
  definedStores.add(storeId)

  return () => {
    if (activeStores.has(storeId)) {
      return activeStores.get(storeId)
    }

    // Create a "detached scope" to house the store's reactivity so it's not attached to the calling component's "scope."
    // This ensures the store's reactivity won't be affected or die if the initial calling component is destroyed.
    const scope = effectScope(true)
    return scope.run(() => {
      const storeDefs = fn()
      const state = storeDefs.state
      if (!isReactive(state)) {
        throw new Error('state must be a reactive() object.')
      }

      initialStates.set(storeId, cloneDeep(state))

      function $reset(keys = Object.keys(state)) {
        const initialState = initialStates.get(storeId)
        keys.forEach((key) => (state[key] = cloneDeep(initialState[key])))
      }

      // Sets one or more properties of state. Explicitly errors on keys not in state to prevent
      // new state properties from popping into existence outside of what's initially defined.
      function $patch(payload = {}) {
        Object.entries(payload).forEach(([key, value]) => {
          if (typeof state[key] !== 'undefined') {
            state[key] = value
          } else {
            throw new Error(
              `Attempting to $patch() key ${key} of store ${storeId}, but ${key} is not defined on state.`,
            )
          }
        })
      }

      function $set(payload = {}) {
        $reset()
        $patch(payload)
      }

      function $dispose() {
        scope.stop()
        removeStore(storeId)
      }

      const store = {
        $set,
        $patch,
        $reset,
        $dispose,
      }

      function addToStore(obj = {}) {
        Object.entries(obj).forEach(([key, value]) => {
          if (key in store) {
            throw new Error(
              `Duplicate key "${key}" in store "${storeId}". Make sure your store's computed and method names aren't the same as any state properties.`,
            )
          }
          store[key] = value
        })
      }
      // can't spread into store because that breaks reactivity
      addToStore(storeDefs) // adds what's returned in defineStore (usually state, computeds, methods)
      addToStore(toRefs(state)) // adds state properties as refs

      watch(
        () => store.state,
        () => {
          throw new Error('Do not set the whole state object directly, use $set or $patch.')
        },
      )
      activeStores.set(storeId, store)
      storeTracker.value.push(storeId)
      return store
    })
  }
}
