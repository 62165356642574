<template>
  <div class="load-more">
    <a v-if="!isLoading" :class="buttonClass" href="#" class="btn w-full" @click.prevent.stop="$emit('click')">
      <slot>{{ $t('common.load_more') }}</slot>
    </a>
    <LoadingSpinner v-else />
  </div>
</template>

<script>
import { LoadingSpinner } from 'vue_features/shared/components/ui'

export default {
  name: 'LoadMoreButton',
  components: { LoadingSpinner },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    buttonClass: {
      type: String,
      default: '',
    },
  },
}
</script>
